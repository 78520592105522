import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "gatsby";
import SwiperCore, {
  Navigation,
  Pagination,
  Parallax,
  EffectFade,
} from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { thumparallax } from "common/thumparallax";
import removeSlashFromPagination from "common/removeSlashpagination";

SwiperCore.use([Navigation, Pagination, Parallax, EffectFade]);

const Blogs4 = ({ subServices }) => { 
  const [load, setLoad] = React.useState(true);
  const [services, setServices] = useState(null);

  React.useEffect(() => {
    setTimeout(() => {
      setLoad(false);
      removeSlashFromPagination();
      thumparallax();
    }, 1000);
  }, []);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const paginationRef = React.useRef(null);

  const extractData = (data) => {
    if (data && data.subServices.edges && data.subServices.edges.length > 0) {
      const filter = data.subServices.edges.map((item) => {
        return item.node?.urlSlug?.edges.map((parent) => {
          let payload = {
            ...item.node,
            urlSlug: parent.node.slug,
          };
          return payload;
        });
      });
      return filter.filter((obj) => Object.keys(obj).length !== 0);
    }
  };

  useEffect(() => {
    const data = extractData(subServices);
    if (data && data.length > 0) {
      setServices(data);
    }
  }, [subServices]);

  return (
    <section className="blog-crv sub-bg">
      <h2 style={{ display: "none" }}>&nbsp;</h2>
      <div className="stories">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 no-padding">
              {!load ? (
                <Swiper
                  speed={800}
                  effect="fade"
                  spaceBetween={0}
                  loop={true}
                  parallax={true}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  pagination={{
                    type: "fraction",
                    clickable: true,
                    el: paginationRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                    swiper.params.pagination.el = paginationRef.current;
                  }}
                  onSwiper={(swiper) => {
                    setTimeout(() => {
                      for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].childNodes[0].setAttribute(
                          "data-swiper-parallax",
                          0.75 * swiper.width
                        );
                      }

                      swiper.params.navigation.prevEl =
                        navigationPrevRef.current;
                      swiper.params.navigation.nextEl =
                        navigationNextRef.current;

                      swiper.params.pagination.el = paginationRef.current;

                      swiper.navigation.destroy();
                      swiper.navigation.init();
                      swiper.navigation.update();

                      swiper.pagination.destroy();
                      swiper.pagination.init();
                      swiper.pagination.update();
                    });
                  }}
                  className="swiper-wrapper swiper-container swiper-img"
                  slidesPerView={1}
                >
                  {services &&
                    services.map((val, i) => {
                      return (
                        <SwiperSlide key={i} className="swiper-slide">
                          <div className="item wow fadeIn" data-wow-delay=".3s">
                            <div className="img">
                              <img loading ="lazy"
                                className="thumparallax"
                                src={
                                  val[0]?.imageForServices &&
                                  val[0]?.imageForServices?.guid
                                    ? val[0]?.imageForServices?.guid
                                    : "/img/blog/1.jpg"
                                }
                                alt={val[0]?.altText}
                                style={{ borderRadius: "5px" }}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              ) : null}
            </div>
            <div className="col-lg-6 no-padding valign">
              {!load ? (
                <Swiper
                  speed={800}
                  spaceBetween={0}
                  loop={true}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  pagination={{
                    type: "fraction",
                    clickable: true,
                    el: paginationRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                    swiper.params.pagination.el = paginationRef.current;
                  }}
                  onSwiper={(swiper) => {
                    setTimeout(() => {
                      for (var i = 0; i < swiper.slides.length; i++) {
                        swiper.slides[i].childNodes[0].setAttribute(
                          "data-swiper-parallax",
                          0.75 * swiper.width
                        );
                      }

                      swiper.params.navigation.prevEl =
                        navigationPrevRef.current;
                      swiper.params.navigation.nextEl =
                        navigationNextRef.current;

                      swiper.params.pagination.el = paginationRef.current;

                      swiper.navigation.destroy();
                      swiper.navigation.init();
                      swiper.navigation.update();

                      swiper.pagination.destroy();
                      swiper.pagination.init();
                      swiper.pagination.update();
                    });
                  }}
                  className="swiper-wrapper swiper-container swiper-content"
                  slidesPerView={1}
                >
                  {services &&
                    services.map((value, i) => (
                      <SwiperSlide className="swiper-slide" key={i}>
                        <div className="item wow fadeIn" data-wow-delay=".6s">
                          <div className="content">
                            <div className="tags">
                              <span>Services</span>
                            </div>
                            <div className="title">
                              <h4>
                                <Link
                                  to={`/services/${value[0]?.urlSlug}/${value[0]?.slug}`}
                                >
                                  {value[0]?.title}
                                </Link>
                              </h4>
                            </div>
                            <div className="text">
                              <p>{value[0]?.titleSlogan}</p>
                            </div>
                            <div className="more">
                              <Link
                                to={`/services/${value[0]?.urlSlug}/${value[0]?.slug}`}
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}

                  {/* <SwiperSlide>
                      <div className="item wow fadeIn" data-wow-delay=".6s">
                        <div className="content">
                          <div className="tags">
                            <Link to="/blog/blog-dark">Trending</Link>
                          </div>
                          <div className="info">
                            <Link to="/blog/blog-dark">
                              <i className="far fa-clock"></i>
                              06 Aug 2022
                            </Link>
                            <a href="#0">by Alex Morgan</a>
                          </div>
                          <div className="title">
                            <h4>
                              <Link to="/blog-details/blog-details-dark">
                                List of The Best Investment Projects
                              </Link>
                            </h4>
                          </div>
                          <div className="text">
                            <p>
                              Success is no accident. It is hard work,
                              perseverance, learning, studying, sacrifice and most
                              of all, love of what you are doing.
                            </p>
                          </div>
                          <div className="more">
                            <Link to={`/blog-details/blog-details-dark`}>
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide> */}
                  {/* <SwiperSlide>
                      <div className="item wow fadeIn" data-wow-delay=".6s">
                        <div className="content">
                          <div className="tags">
                            <Link to="/blog/blog-dark">Trending</Link>
                          </div>
                          <div className="info">
                            <Link to="/blog/blog-dark">
                              <i className="far fa-clock"></i>
                              06 Aug 2022
                            </Link>
                            <a href="#0">by Alex Morgan</a>
                          </div>
                          <div className="title">
                            <h4>
                              <Link to="/blog-details/blog-details-dark">
                                World Best Business Website Company
                              </Link>
                            </h4>
                          </div>
                          <div className="text">
                            <p>
                              Success is no accident. It is hard work,
                              perseverance, learning, studying, sacrifice and most
                              of all, love of what you are doing.
                            </p>
                          </div>
                          <div className="more">
                            <Link to={`/blog-details/blog-details-dark`}>
                              Read More
                            </Link>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide> */}
                </Swiper>
              ) : null}
            </div>
          </div>

          <div className="controls">
            <div
              ref={navigationNextRef}
              className="swiper-button-next swiper-nav-ctrl next-ctrl"
            >
              <i className="fas fa-caret-up"></i>
            </div>
            <div
              ref={navigationPrevRef}
              className="swiper-button-prev swiper-nav-ctrl prev-ctrl"
            >
              <i className="fas fa-caret-down"></i>
            </div>
            <div className="swiper-pagination" ref={paginationRef}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogs4;
