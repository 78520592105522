import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";

SwiperCore.use([Navigation]);

const Works2 = ({ Projects }) => {
  const ProjectData = Projects?.recentProjects;
  const [load, setLoad] = React.useState(true);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [projectLength, setProjectLength] = useState(null);

  React.useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (ProjectData?.edges && ProjectData?.edges.length > 0) {
      setProjectLength(ProjectData?.edges.length);
    }
  }, [ProjectData]);

  return (
    <>
      <section
        className="work-carousel metro position-re"
        style={{ marginTop: "5rem" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 no-padding">
              <div className="sec-head  text-center">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  Projects
                </h6>
              </div>
              <div className="swiper-container">
                {!load ? (
                  <Swiper
                    className="swiper-wrapper"
                    slidesPerView={1}
                    centeredSlides={
                      projectLength && projectLength > 1 ? true : false
                    }
                    loop={projectLength && projectLength > 2 ? true : false}
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                      swiper.params.navigation.prevEl =
                        navigationPrevRef.current;
                      swiper.params.navigation.nextEl =
                        navigationNextRef.current;
                    }}
                    onSwiper={(swiper) => {
                      setTimeout(() => {
                        swiper.params.navigation.prevEl =
                          navigationPrevRef.current;
                        swiper.params.navigation.nextEl =
                          navigationNextRef.current;

                        swiper.navigation.destroy();
                        swiper.navigation.init();
                        swiper.navigation.update();
                      });
                    }}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    speed={1000}
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      767: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                      },
                      991: {
                        slidesPerView: 2,
                      },
                    }}
                  >
                    {ProjectData &&
                      ProjectData?.edges?.map((item, index) => {
                        return (
                          <SwiperSlide className="swiper-slide" key={item.id}>
                            <div
                              className="content wow noraidus fadeInUp"
                              data-wow-delay=".3s"
                            >
                              <div
                                className="item-img bg-img wow imago"
                                style={{
                                  backgroundImage: `url(${item.node.featureImage.guid})`,
                                }}
                              />
                              <div className="cont">
                                <h6 className="color-font">
                                  <a href="#0">{item.node.projectName}</a>
                                </h6>
                                <h4>
                                  <Link to={`/work/${item.node.slug}`}>
                                    {item.node.shortDescription}
                                  </Link>
                                </h4>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>
                ) : null}

                <div
                  ref={navigationNextRef}
                  className="swiper-button-next swiper-nav-ctrl simp-next cursor-pointer"
                >
                  <span className="simple-btn right">Next</span>
                </div>
                <div
                  ref={navigationPrevRef}
                  className="swiper-button-prev swiper-nav-ctrl simp-prev cursor-pointer"
                >
                  <span className="simple-btn">Prev</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Works2;
