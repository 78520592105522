  import React, { useEffect, useState } from "react";
  import Navbar from "components/Navbar/navbar";
  import Footer from "components/Footer/footer";
  import ServicesIntroduction from "components/Services-introduction";
  import ServicesDescription from "components/Services-description";
  import Services6 from "components/Services6/services6";
  import ProjectDate from "data/project-details2.json";
  import DarkTheme from "layouts/Dark";
  import Faq from "components/Faq/faq";
  import CallToAction from "components/Call-to-action/call-to-action";
  import Works2 from "components/Works2/works2";
  import Blogs4 from "components/blogs/Blogs4/blogs4";
  import Testimonials from "components/Testimonials/testimonials";
  import ParticalsOverlay from "components/Particals-Overlay/ParticalsOverlay";
  import SEO from "components/seo/seo";
  import Services8 from "components/Services8/services8";
  import SContactForm from "components/s-contact-form/s-contact-form";
  import { useStaticQuery, graphql } from "gatsby";
  import LoadingScreen from "components/Loading-Screen";
  import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
  import ServiceWithTestimonials from "components/Service-testimonials";
  import FullTestimonials from "components/Full-testimonials/full-testimonials";
  import OurProcess from "components/Our-process";
  import Services5 from "components/Services5/services5";
  import Services9 from "components/Services9.jsx";
  import IntroWithSlider from "components/Intro-with-slider/intro-with-slider";
  import HeaderServices from "components/Header-Services";
  import Slider from "react-slick";

  const ProjectDetails2Dark = (props) => {

    const dmArrows = {
        top:"-30px",
        position:"relative",
    };

    const data = useStaticQuery(graphql`
      query {
        allWpService {
          nodes {
            altText
            title
            subTitle
            slug
            description
            serviceIntroHead
            serviceIntroDetail
            servicesTypesHead
            servicesTypesTagline
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            extraContentDetail
            aboutServiceImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            aboutService
            serviceImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            technologiesData {
              nodes {
                slug
                title
              }
            }
            caseStudiesDetail
            caseStudiesHead
            caseStudies {
              nodes {
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                projectName
                shortDescription
                slug
              }
            }
            aprroachHead
            approachDetail
            processForService {
              nodes {
                processDetail
                processHead
              }
            }
            technologiesForService {
              nodes {
                title
                slug
                technologyIconForService {
                  mediaItemUrl
                }
                technologyDescription
              }
            }
            faqs {
              nodes {
                faqDescription
                faqTitle
              }
            }
          }
        }
      }
    `);

    const service = data.allWpService.nodes;
    const technologiesData = props.pageContext.data;

    const liveUrl =
      typeof window !== "undefined" ? new URL(window.location.href) : null;

    const queryParameters = liveUrl ? liveUrl.searchParams : null;
    const selectedServiceFromURL = queryParameters
      ? queryParameters.get("selectedService")
      : null;
    const selectedTechnologyFromURL = queryParameters
      ? queryParameters.get("selectedTechnology")
      : null;

    const [selectedService, setSelectedService] = useState(
      props.location.state
        ? props.location.state.selectedService
        : selectedServiceFromURL
    );
    const [selectedTechnology, setSelectedTechnology] = useState(
      props.location.state
        ? props.location.state.selectedTechnology
        : selectedTechnologyFromURL
    );

    const [slider, setSlider] = useState();

    const [isSubService, setIsSubService] = useState(false);
    useEffect(() => {});
    const navbarRef = React.useRef(null);
    const logoRef = React.useRef(null);
    const MainContent = React.useRef(null);
    const fixedSlider = React.useRef(null);
    const fixedHeader = React.useRef(null);
    const [theme, setTheme] = useState("Dark");

    setInterval(() => {
      if (fixedSlider.current) {
        var slidHeight = fixedSlider.current.offsetHeight;
      }
      if (MainContent.current) {
        MainContent.current.style.marginTop = slidHeight + "px";
      }
    }, 1000);

    useEffect(() => {
      const pathname = liveUrl ? liveUrl.pathname : "";
      const pathSegments = pathname
        .split("/")
        .filter((segment) => segment !== "");

      const extractedService = pathSegments[1]; // Change this index based on your URL structure
      const extractedTechnology = pathSegments[2]; // Change this index based on your URL structure

      if (!selectedService && extractedService) {
        setSelectedService(extractedService);
      }

      if (!selectedTechnology && extractedTechnology) {
        setSelectedTechnology(extractedTechnology);
      }

      var navbar = navbarRef.current;

      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }

      window.addEventListener("scroll", () => {
        if (window.pageYOffset > 300) {
          navbar.classList.add("nav-scroll");
        } else {
          navbar.classList.remove("nav-scroll");
        }
      });

      setTimeout(() => {
        if (fixedHeader.current) {
          var slidHeight = fixedHeader.current.offsetHeight;
        }
        if (MainContent.current) {
          MainContent.current.style.marginTop = slidHeight + "px";
        }
      }, 1000);

      setIsSubService(pathSegments.length > 2);
    }, [
      selectedService,
      liveUrl,
      selectedTechnology,
      setIsSubService,
      fixedHeader,
      navbarRef,
    ]);

    const themeModeChange = (themeMode) => {
      let bodyEl = document.querySelector("body");
      if (themeMode == "Dark") {
        setTheme("themeL");
        bodyEl.classList.add("themeL");
        localStorage.setItem("theme", "themeL");
      } else {
        setTheme("Dark");
        bodyEl.classList.remove("themeL");
        localStorage.removeItem("theme");
      }
    };

    return (
      <>
      <style>{
      `span.text-toggle.cursor-pointer.color-font {
            font-size: xx-small;
        }`}
      </style>
      <DarkTheme>
        <LoadingScreen/>
        <Navbar
          nr={navbarRef}
          lr={logoRef}
          theme={theme}
          themeModeChange={themeModeChange}
        />
      

        {selectedService && !isSubService && (
          <>
          
            {service.map((singleService) =>
              singleService.slug === selectedService ? (
                
                <React.Fragment key={singleService.slug}>
                  {/* <div className="container">
                    <div className="service-head-container">
                      <div className="service-head-img">
                        <GatsbyImage loading ="lazy"
                          image={singleService?.serviceImage?.localFile?.childImageSharp?.gatsbyImageData}
                          alt={singleService.altText}
                        />
                      </div>
                      <div className="head-details">
                        <div className="mb-5">
                        <h1 className="animate-charcter"><strong>{singleService.title} Services</strong></h1>
                        <p>{singleService.subTitle}</p>
                        </div>
                        
                        <button className='nb butn bord my-4 curve apply-btn' type="submit">
                  <span className="color-font">Get free consultation</span>
                </button>
                      </div>
                    </div>
                  </div> */}

  <div className="container mt-5">
                
                    <HeaderServices servicesData={singleService}/>
                      </div>

                  <ParticalsOverlay />
                  
              
                  <div ref={MainContent} className="main-content">
                  
                    {technologiesData.title == "Digital Marketing" ?  <Services9 servicesData={technologiesData} props={props} /> :   <Services8 servicesData={[singleService]} props={props} />} 
                  {technologiesData.title == "Digital Marketing" && <OurProcess/>} 
                    {technologiesData.testimonialsData && (
                      <div className="testimonials section-padding pb-0">
             <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 col-md-10">
            <div className="sec-head  text-center">
              <h6 className="wow fadeIn" data-wow-delay=".5s">
                Testimonials
              </h6>
              <h3 className="wow color-font">
                We love our clients from all over the world.
              </h3>
            </div>
          </div>
        </div>
      </div>

                        <section className="testimonials">
                      <div className="container-fluid position-re">
                      <div className="row wow fadeInUp" data-wow-delay=".5s">
                        <div className="col-lg-12">
                          <Slider
                            className="slic-item"
                            {...{
                              ref: (c) => setSlider(c),
                              dots: false,
                              infinite: true,
                              arrows: true,
                              centerMode: true,
                              autoplay: false,
                              rows: 1,
                              slidesToScroll: 1,
                              slidesToShow: 3,
                              centerPadding: 0,
                              responsive: [
                                {
                                  breakpoint: 1024,
                                  settings: {
                                    slidesToShow: 1,
                                    centerMode: false,
                                  },
                                },
                                {
                                  breakpoint: 767,
                                  settings: {
                                    slidesToShow: 1,
                                    centerMode: false,
                                  },
                                },
                                {
                                  breakpoint: 480,
                                  settings: {
                                    slidesToShow: 1,
                                    centerMode: false,
                                  },
                                },
                              ],
                            }}
                          >
                            <div className="item">
                              <div className="info valign">
                                <div className="cont">
                                  <div className="author">
                                    <div style={{background:'white'}} className="img">
                                      <StaticImage  loading ="lazy"
                                      src="../../../static/img/testimonials/AmitBansal.png"
                                      alt=""
                                    />
                                    </div>
                                    <h6 className="author-name color-font">
                                      Amit Bansal
                                    </h6>
                                    <span className="author-details">
                                                                    
                                    </span>
                                  </div>
                                </div>
                              </div>
                                <TruncatedText text="We had a great time with your team and your team worked extra hard in giving us the required services. We are especially amazed at the kind of research, the team at Evolvan did, for coming up with posts for BNI Himachal. Really hats off to your dedication. You really exemplified how Social Media marketing is not just about making beautiful creatives, but much more than that."/>
                            </div>

                            <div className="item">
                              <div className="info valign">
                                <div className="cont">
                                  <div className="author">
                                    <div className="img" style={{background:'white'}}>
                                    <StaticImage  loading ="lazy"
                                      src="../../../static/img/testimonials/GuneetSinghTalwar.png"
                                      alt=""
                                    />
                                    </div>
                                    <h6 className="author-name color-font">
                                      Guneet Singh Talwar
                                    </h6>
                                    <span className="author-details">
                                                                    
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <p className="d-flex align-items-center">Company does more than what is actually given to them, as the name, You get more of Solutions, innovations Commendable work."</p>
                            </div>
                            
                            <div className="item">
                              <div className="info valign">
                                <div className="cont">
                                  <div className="author">
                                    <div className="img" style={{background:'white'}}>
                                    <StaticImage  loading ="lazy"
                                      src="../../../static/img/testimonials/Aura-Finance.png"
                                      alt=""
                                    />
                                    </div>
                                    <h6 className="author-name color-font">
                                    Aseem Aggarwal
                                    </h6>
                                    <span className="author-details">
                                                                    
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <p className="d-flex align-items-center">One of the "Best Digital Marketing Company in Ludhiana" Very Good Performance & Excellence Service.</p>
                            </div>

                            {/* <div className="item">
                              <div className="info valign">
                                <div className="cont">
                                  <div className="author">
                                    <div className="img" style={{background:'white'}}>
                                    <StaticImage  loading ="lazy"
                                      src="../../../static/img/testimonials/GuneetSinghTalwar.png"
                                      alt=""
                                    />
                                    </div>
                                    <h6 className="author-name color-font">
                                      Natasha Khanna
                                    </h6>
                                    <span className="author-details">
                                                                    
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <p>Thank you for all your support and good wishes always.</p>
                            </div> */}
                            
                            <div className="item">
                              <div className="info valign">
                                <div className="cont">
                                  <div className="author">
                                    <div className="img" style={{background:'white'}}>
                                    <StaticImage  loading ="lazy"
                                      src="../../../static/img/testimonials/Oner.png"
                                      alt=""
                                    />
                                    </div>
                                    <h6 className="author-name color-font">
                                      Rishav Jain
                                    </h6>
                                    <span className="author-details">
                                                                    
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <p className="d-flex align-items-center" >We have been associated with Evolvan from last more than one year They have been managing our Ecommerce website and social media. They have managed it perfectly with all the guidance and good services.</p>
                            </div>
                            
                            <div className="item">
                              <div className="info valign">
                                <div className="cont">
                                  <div className="author">
                                    <div className="img" style={{background:"white"}}>
                                    <StaticImage  loading ="lazy"
                                      src="../../../static/img/testimonials/NF.png"
                                      alt=""
                                    />
                                    </div>
                                    <h6 className="author-name color-font">
                                      Rachita Gambhir
                                    </h6>
                                    <span className="author-details">
                                                                    
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <p className="d-flex align-items-center">Kudos to Evolvan team for their outstanding work! They've really helped us boost our online presence and drive more traffic to our Instagram page. Keep up the great work</p>
                            </div>

                          </Slider>

                        </div>
                      </div>
                    </div>  
                      </section>

                      <div style={dmArrows} className="arrows">
                      <div
                          onClick={() => slider.slickPrev()}
                          className="prev cursor-pointer"
                        >
                          <span className="pe-7s-angle-left"></span>
                        </div>

                        <div
              onClick={() => slider.slickNext()}
              className="next cursor-pointer"
            >
              <span className="pe-7s-angle-right"></span>
                        </div>
                      </div>   

                      </div>

                )
              }
              
                    <SContactForm servicesData={[singleService]} props={props}/>
                    <Footer />
                  </div>
                </React.Fragment>
              ) : null
            )}
            
          </>
        )}

        {selectedTechnology && isSubService && (
          <>
            <header
              ref={fixedHeader}
              className="works-header fixed-slider hfixd valign sub-bg custom-background"
              style={{
                backgroundImage: `url(${
                  technologiesData &&
                  technologiesData?.serviceHeroImage &&
                  technologiesData?.serviceHeroImage?.guid
                    ? technologiesData?.serviceHeroImage?.guid
                    : ProjectDate.projectHeaderImage
                })`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <ParticalsOverlay />
              <div className="circle-bg">
                <div className="circle-color fixed">
                  <div className="gradient-circle"></div>
                  <div className="gradient-circle two"></div>
                </div>
              </div>
              <div className="subservice-container container">
                <div className="row">
                  <div className="col-lg-7 col-md-9">
                    <div className="cont">
                      <h1 className="animate-charcter">{technologiesData?.title} Company</h1>
                      <p className="service-desc mt-3">
                        {technologiesData?.titleSlogan}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div ref={MainContent} className="main-content custom-margin">
              <ServicesIntroduction projectIntroductionData={technologiesData} />
              <ServicesDescription projectStrategyData={technologiesData} />

              <Services6 serviceTechnologies={technologiesData} />
              <Faq faqs={technologiesData} />

              {technologiesData.testimonials.edges.length > 1 && (
                <Testimonials testimonialsData={technologiesData.testimonials} />
              )}

              {technologiesData.subServices?.edges.length > 0 && (
                <Blogs4 subServices={technologiesData} />
              )}

              {technologiesData.recentProjects && (
                <Works2 Projects={technologiesData} />
              )}

              <CallToAction />

              <Footer />
            </div>
          </>
        )}
      </DarkTheme>
      </>
    );
  };

  const TruncatedText = ({ text }) => {
    const[isExpanded, setIsExpanded] = useState(false);
    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    }

    const maxLength = 200;
    
    // Function to truncate the text to the last complete word
    const getTruncatedText = (text, maxLength) => {
      if (text.length <= maxLength) return text;
       
      const truncated = text.slice(0,maxLength);
      const lastSpaceIndex = truncated.lastIndexOf(' ');
      return  lastSpaceIndex > -1 ? truncated.slice(0, lastSpaceIndex) + '...' : truncated ;
    }
    return(
      <p>
        {isExpanded ? text :  getTruncatedText(text, maxLength)}
        <div className="text-toggle cursor-pointer color-font " onClick={toggleExpand}>
          {isExpanded ? "Show less" : "Show more"}
        </div>
      </p>
    );
  };

  export const Head = (props) => {
    const seoData = props.pageContext.data;
    const dynamicMetaTitle = seoData?.ssMetaTitle;
    const dynamicMetaDescription = seoData?.ssMetaDescription;
    const liveUrl = typeof window !== "undefined" ? window.location.href : "";

    return (
      <>
        <SEO
          canonical={liveUrl}
          title={dynamicMetaTitle}
          description={dynamicMetaDescription}
        />
        <link
          rel=""
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap"
        />
      </>
    );
  };

  export default ProjectDetails2Dark;
