import React, { useState } from "react";
import cardMouseEffect from "common/cardMouseEffect";
import { thumparallaxDown } from "common/thumparallax";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
const TruncHtml = require("trunc-html");

const OurProcess = () => {
  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);

  return (
    <>
     <section className="min-area sub-bg revSec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-12 valign ">
              <div className="content pl-0">
              <div className="row justify-content-center">
<div className="col-lg-8 col-md-10">
<div className="sec-head text-center">

<h4 className="ind color-font">Industries We Serve</h4>
<p>
At <span className="font-weight-bold" > Evolvan </span> , we specialize in delivering tailored digital marketing solutions across a variety of industries, ensuring that each client achieves optimal growth and success. Here’s how we cater to different sectors
</p>
</div>
</div>
</div>
                <ul id="revSec" className="feat">
                  <li
                    className="wow fadeInUp about-item-box"
                    data-wow-delay=".2s"
                  >
                    <h6>Education</h6>
                    <p className="pl-0">
                    From schools to universities and ed-tech platforms, we create campaigns that enhance visibility and drive student enrollment. Our strategies increase engagement through personalized content, SEO optimization, and targeted social media marketing.
                    </p>
                  </li>
                  <li
                    className="wow fadeInUp about-item-box"
                    data-wow-delay=".4s"
                  >
                    <h6>Health</h6>
                    <p className="pl-0">
                    We help healthcare providers, clinics, and wellness centers improve their online presence. Our services ensure that patients find the right care through SEO, PPC, and social media strategies focused on trust, credibility, and accessibility.
                    </p>
                  </li>
                  <li
                    className="wow fadeInUp about-item-box"
                    data-wow-delay=".6s"
                  >
                    <h6>Restaurants</h6>
                    <p className="pl-0">
                    We help restaurants and food chains enhance customer engagement, manage reviews, and drive foot traffic through effective social media marketing, location-based targeting, and online ordering systems integration.
                    </p>
                  </li>
                  <li
                    className="wow fadeInUp about-item-box"
                    data-wow-delay=".6s"
                  >
                    <h6>E-commerce</h6>
                    <p className="pl-0">
                    For e-commerce businesses, we provide a complete suite of digital marketing services from SEO to Google Ads, designed to maximize online sales, boost website traffic, and convert visitors into loyal customers.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="line right"></div>

      <section className="min-area sub-bg about-us">
        <div className="container">
          <div className="col-lg-12 valign">
            <div className="content pt-0 d-flex flex-wrap justify-content-center w-100">
              <div className="col-lg-4 d-flex justify-content-center w-100">
                <h4 className="wow color-font">Our Process</h4>
              </div>

              {/* <div className="col-lg-8">
                <p className="wow txt" data-splitting>
                  At Evolvan Solutions, we believe that technology is the key to
                  driving business success. We are constantly exploring new
                  technologies and approaches to help our clients stay ahead of
                  the curve. We also work closely with our clients to understand
                  their specific needs and develop customized solutions that
                  meet their requirements.
                </p>
              </div> */}
            </div>
          </div>

          <div className="row w-100">
            <div className="col-lg-4 d-flex align-items-center">
              <div className="img">
                <StaticImage loading ="lazy"
                  className="thumparallax-down"
                  src="../../images/About-us.png"
                  alt="about"
                />
              </div>
            </div>
            <div className="col-lg-8 valign">
              <div className="content pt-0">
                <ul id="about-us" className="feat">
                  <li className="wow fadeInUp" data-wow-delay=".2s">
                    {/* <h5>Why choose Evolvan ?</h5> */}
                    <br />
                    <h6>Discovery</h6>
                    <p>
                    We understand your business goals, target audience, and challenges.
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".4s">
                    <h6>Strategizing</h6>
                    <p>
                    Tailor a marketing plan with the best tools and techniques.
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".6s">
                    <h6>Execution</h6>
                    <p>
                    Implement the plan using our services such as social media, SEM, and more.
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".6s">
                    <h6>Monitoring</h6>
                    <p>
                    Track performance, make adjustments, and optimize for better results
                    </p>
                  </li>
                  <li className="wow fadeInUp" data-wow-delay=".6s">
                    <h6>Reporting</h6>
                    <p>
                    Deliver actionable insights and reports to keep you informed of progress.{" "}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurProcess;
